<template>
  <el-dialog title="通知成员选择" :visible.sync="getData.dialogVisible" :before-close="handleClose" width="800">
    <div class="dept-member">
      <el-form ref="rolesUserForm" :inline="true" style="padding: 20px">
        <el-form-item label="通知成员">
          <div class="notify-dept">
            <DingTalkDeptSelect style="float: left" ref="refDngTalkDept" @check="getDeptMembers" />
            <div class="all_box">
              <div class="check-all">
                <el-checkbox :indeterminate="dialogData.isIndeterminate" v-model="dialogData.checkAll" @change="handleCheckAllChange" style="float: left">
                  全选({{ checkedMembers.length }}/{{ members.length }})
                </el-checkbox>
              </div>
              <div class="member-list">
                <el-checkbox-group v-model="checkedMembers" @change="handleCheckedCitiesChange">
                  <el-checkbox v-for="member in members" :label="member" :key="member.userid" size="large" border>
                    <el-tooltip :content="member.position" placement="bottom" effect="light">
                      <span
                        >{{ member.name }} <span>{{ $utils.encryptMobile(member.mobile) }}</span></span
                      >
                    </el-tooltip>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="button_box">
        <el-button type="primary" @click="sendNotifyToDepts" icon="el-icon-chat-line-square" :disabled="dialogData.loadingNotify" :loading="dialogData.loadingNotify">钉钉通知 </el-button>
        <el-button type="text" @click="dialogData.showTaskList = !dialogData.showTaskList">
          通知记录
          <span :class="[dialogData.showTaskList ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></span>
        </el-button>
        <div v-show="dialogData.showTaskList" class="notify-record">
          <el-link icon="el-icon-refresh" @click="getNotifyTaskList">通知历史记录：</el-link>
          <!--                    <NotifyTaskList :data-list="taskList" @rowDblclick="showNotifyTask"/>-->
          <el-table :data="taskList" highlight-current-row>
            <!--      <el-table-column prop="id" label="任务Id"></el-table-column>-->
            <el-table-column prop="sender_name" label="发送人" width="80"></el-table-column>
            <el-table-column prop="send_status_alias" label="发送状态" width="70"></el-table-column>
            <el-table-column prop="send_time" label="发送时间" width="140" show-overflow-tooltip></el-table-column>
            <el-table-column prop="msg_param.title" label="标题" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="msg_param.text" label="内容" show-overflow-tooltip></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import DingTalkDeptSelect from '@/pages/departments/components/DingTalkDeptSelect'

export default {
  name: 'dingTalkNotify',
  components: { DingTalkDeptSelect },
  data() {
    return {
      dialogData: {
        isIndeterminate: true,
        checkAll: false,
        loadingNotify: false,
        showTaskList: false,
      },
      checkedMembers: [],
      members: [],
      taskList: [],
      getData: {
        head: {
          id: '',
        },
      },
    }
  },
  props: {
    dataJson: {
      type: String,
      default: '',
    },
  },
  watch: {
    dataJson: {
      immediate: true,
      handler(n) {
        this.getData = JSON.parse(n)
        if (this.getData.dialogVisible) {
          this.getNotifyTaskList()
        }
      },
    },
  },
  mounted() {
    // this.getNotifyTaskList()
  },
  methods: {
    // 成员选择模块
    getDeptMembers(data, checkedObj) {
      let depts = checkedObj.checkedNodes
      this.defaultCheckBox()
      depts.forEach((item) => {
        this.loadMembers({ dpt_id: item.dpt_id })
      })
    },
    handleCheckAllChange(val) {
      this.checkedMembers = val ? [...this.members] : []
      this.dialogData.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.dialogData.checkAll = checkedCount === this.members.length
      this.dialogData.isIndeterminate = checkedCount > 0 && checkedCount < this.members.length
    },

    async loadMembers(searchCondition) {
      searchCondition.page_size = 50
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      list.forEach((item) => {
        let index = this.members.findIndex((value) => value.userid === item.user_id)
        if (index === -1) this.members.push(item)
      })
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        this.members = [...this.members, ...list]
      }
    },
    defaultCheckBox() {
      this.dialogData.checkAll = false
      this.checkedMembers = []
      this.members = []
      this.dialogData.isIndeterminate = true
    },

    async getNotifyTaskList() {
      let { list } = await this.$api.dingTalkNotifyHistory(this.getData.head.id)
      this.$nextTick(() => {
        this.taskList = list
        this.showNotify = false
        // this.getList()
      })
    },

    showNotifyTask(row) {
      //历史通知
      let reqParam = row.req_param
      this.checked = reqParam.other || []
      this.opType = reqParam.op_type || null
      this.$refs.refDngTalkDept.setCheckedKeys(reqParam.dept_ids || [])
    },
    //钉钉通知发送
    sendNotifyToDepts() {
      let postData = { id: this.getData.head.id, type: this.getData.head.type, rec_user_ids: [] }
      if (this.checkedMembers.length > 0) {
        this.checkedMembers.forEach((item, index) => {
          postData.rec_user_ids[index] = item.userid
        })
      }
      this.$confirm(`确认发送钉钉通知?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.notifyDingTalk(postData)
        })
        .catch(() => {
          this.$notify.info('已取消通知')
        })
    },
    async notifyDingTalk(postData) {
      this.dialogData.loadingNotify = true
      let info = await this.$api.dingTalkNotifyartistDivide(postData)
      this.dialogData.loadingNotify = false
      if (info) {
        this.$notify.success('发送成功')
        this.handleClose()
      }
    },
    handleClose() {
      this.getData = {
        head: {
          id: '',
        },
      }
      this.$emit('close', this.getData)

    },
  },
}
</script>

<style scoped lang="scss">
.dept-member {
  width: 90%;
  border: #dcdfe6 1px solid;
  min-height: 500px;
  margin: 0 auto;
}

.notify-dept {
  width: 500px;
  border: #dcdfe6 1px solid;
  padding: 10px;
  /*max-height: 300px;*/
  overflow-y: scroll;
}

.all_box {
  margin-left: 90px;
  /*margin-top: 20px;*/
  float: left;
}

.check-all {
  height: 20px;
  padding: 5px;
  margin-bottom: 5px;
}

.member-list {
  > div > label {
    display: block;
    width: 180px;
    margin: 10px;
    padding: 0;
  }
}

.button_box {
  text-align: center;

  > .notify-record {
    margin-top: 10px;
    padding: 10px;
    max-height: 200px;
    overflow-y: scroll;
  }
}
</style>